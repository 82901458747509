<template>
  <div class="CANCELED" />
  <!--
  <base-section id="relied">
    <base-section-heading
      space="24"
      color="primary lighten-2"
      :title="$i18n.t('title')"
    />
  </base-section>
  -->
</template>

<script>
  export default {
    name: 'Relied',
  }
</script>
<i18n>
{
	"en": {
		"title": "We have confided in"
	},
	"it": {
		"title": "Ci siamo affidati a"
	}
}
</i18n>
